// src/context/AuthUIContext.tsx

import React, {createContext, ReactNode, useState} from 'react';

interface AuthUIContextProps {
  isAuthenticatorVisible: boolean;
  showAuthenticator: () => void;
  hideAuthenticator: () => void;
}

// Create the context
export const AuthUIContext = createContext<AuthUIContextProps>({
  isAuthenticatorVisible: false,
  showAuthenticator: () => {
  },
  hideAuthenticator: () => {
  },
});

// Define the props interface including 'children'
interface AuthUIProviderProps {
  children: ReactNode;
}

export const AuthUIProvider: React.FC<AuthUIProviderProps> = ({children}) => {
  const [isAuthenticatorVisible, setIsAuthenticatorVisible] = useState(false);

  const showAuthenticator = () => setIsAuthenticatorVisible(true);
  const hideAuthenticator = () => setIsAuthenticatorVisible(false);

  return (
    <AuthUIContext.Provider
      value={{
        isAuthenticatorVisible,
        showAuthenticator,
        hideAuthenticator,
      }}
    >
      {children}
    </AuthUIContext.Provider>
  );
};
