// src/components/Home.tsx
import React from 'react';
import RecipeList from './recipes/RecipeList';
import {Recipe} from '../types';
import {useNavigate} from 'react-router-dom';
import {API_BASE_URL} from '../apiConfig';
import {useTranslation} from 'react-i18next';
import CreateRecipe from "./recipes/CreateRecipe";
import SearchBar from "./search/SearchBar";


const Home: React.FC = () => {
  const [recipes, setRecipes] = React.useState<Recipe[]>([]);
  const navigate = useNavigate();
  const {t, i18n} = useTranslation();
  const lang = (i18n.language?.split('-')[0]) || 'en';

  const fetchRandomRecipe = async () => {
    // Replace with your API URL
    const response = await fetch(`${API_BASE_URL}/recipes/random?lang=${lang}`);
    const data = await response.json();
    setRecipes([data]);
    // Redirect to the RecipeDetails route
    navigate(`/recipes/${data.id}`, {state: {recipe: data}});
  };

  return (
    <>
      <SearchBar/>
      <RecipeList recipes={recipes}/>
      <CreateRecipe/>
      <button onClick={fetchRandomRecipe}>{t('fetchRandomRecipe')}</button>
    </>
  );
};

export default Home;
