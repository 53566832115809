import React, {useState} from 'react';
import {Button, Typography} from '@mui/material';

interface SelectionGroupProps {
  groupName: string;
  options: string[];
  isSingleSelection: boolean;
  onSelectionChange: (selectedOptions: string[]) => void;
}

const SelectionGroup: React.FC<SelectionGroupProps> = ({
                                                         groupName,
                                                         options,
                                                         isSingleSelection,
                                                         onSelectionChange,
                                                       }) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const handleSelectionChange = (option: string) => {
    let updatedOptions: string[];

    if (isSingleSelection) {
      // Single selection logic
      updatedOptions = selectedOptions.includes(option) ? [] : [option];
    } else {
      // Multi-selection logic
      updatedOptions = selectedOptions.includes(option)
        ? selectedOptions.filter((item) => item !== option)
        : [...selectedOptions, option];
    }

    setSelectedOptions(updatedOptions);
    onSelectionChange(updatedOptions); // Notify parent about the changes
  };

  return (
    <div style={{marginBottom: '20px'}}>
      <Typography variant="h6" gutterBottom>
        {groupName}
      </Typography>
      <div style={{display: 'flex', flexWrap: 'wrap', gap: '10px'}}>
        {options.map((option) => (
          <Button
            key={option}
            variant={selectedOptions.includes(option) ? 'contained' : 'outlined'}
            // color={selectedOptions.includes(option) ? 'primary' : 'default'}
            onClick={() => handleSelectionChange(option)}
            style={{
              textTransform: 'none',
              padding: '8px 16px',
              borderRadius: '20px',
            }}
          >
            {option}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default SelectionGroup;
