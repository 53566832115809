// src/Routes.tsx
import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Home from './components/Home';
import RecipeDetails from './components/recipes/RecipeDetails';
import ProtectedView from "./components/auth/ProtectedView";
import MySpace from "./components/personal-space/MySpace";
import SearchPage from "./components/search/SearchPage";


const AppRoutes: React.FC = () => (
  <Routes>
    <Route path="/" element={<Home/>}/>
    <Route path="/recipes/:id" element={<RecipeDetails recipe={null}/>}/>
    <Route path="/recipes/search" element={<SearchPage />} />
    <Route element={<ProtectedView/>}>
      <Route path="/my-space" element={<MySpace/>}/>
    </Route>
  </Routes>
);

export default AppRoutes;
