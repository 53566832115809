export interface FilterConfig {
  options: string[];
  isSingleSelection: boolean;
}

const filterConfig: Record<string, FilterConfig> = {
  'Course Type': {
    options: ['Appetizer', 'Main dish', 'Dessert', 'Side dish', 'Salad', 'Soup', 'Snack'],
    isSingleSelection: true,
  },
  Cuisine: {
    options: ['Italian', 'Mexican', 'Chinese', 'Indian', 'French', 'Japanese'],
    isSingleSelection: true,
  },
  'Dietary Preferences': {
    options: ['Vegan', 'Vegetarian', 'Gluten-free', 'Dairy-free', 'Nut-free', 'Low-carb'],
    isSingleSelection: false,
  },
};

export default filterConfig;
