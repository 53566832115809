import React from "react";
import './MySpace.css';

const MySpace: React.FC = () => {

  return <div className='mydivtodoreplace'>
    <p>MySpace - Work in progress
    </p></div>;
}

export default MySpace;