// src/components/AuthenticatorModal.tsx

import React, {useContext, useEffect} from 'react';
import {Authenticator, Button, useAuthenticator, View} from '@aws-amplify/ui-react';

import {AuthUIContext} from './AuthUIContext';
import {useNavigate} from 'react-router-dom';
import './AuthenticatorModal.css';

const AuthenticatorModal: React.FC = () => {
  const {isAuthenticatorVisible, hideAuthenticator} = useContext(AuthUIContext);
  const {user} = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();

  // Hide the authenticator when the user signs in
  useEffect(() => {
    if (user && isAuthenticatorVisible) {
      hideAuthenticator();
    }
  }, [user, isAuthenticatorVisible, hideAuthenticator]);

  // Custom 'Skip' button component
  const SkipButton = () => (
    <Button
      className="custom-link-button"
      variation="link"
      onClick={() => {
        hideAuthenticator();
        // Redirect to a different page if desired
        navigate('/', {replace: true});
      }}
    >
      Skip for now
    </Button>
  );

  // Customize the components within the Authenticator
  const components = {
    SignIn: {
      Footer() {
        const {toForgotPassword} = useAuthenticator();
        return (
          <View textAlign="center">
            <Button
              className="custom-link-button"
              fontWeight="normal"
              onClick={toForgotPassword}
              size="small"
              variation="link"
            >
              Reset Password
            </Button>
            <SkipButton/>
          </View>
        );
      },
    },
    SignUp: {
      Footer: SkipButton,
    },
    ForgotPassword: {
      Footer: SkipButton,
    },
  };

  return (
    <>
      {isAuthenticatorVisible && (
        <div className="authenticator-dialog">
          <Authenticator
            variation="modal"
            signUpAttributes={['name', 'preferred_username']}
            components={components}
          />
        </div>
      )}
    </>
  );
};

export default AuthenticatorModal;
