// src/components/ProtectedView.tsx

import React, { useContext, useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Outlet } from 'react-router-dom';
import { AuthUIContext } from './AuthUIContext';

const ProtectedView: React.FC = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const { isAuthenticatorVisible, showAuthenticator } = useContext(AuthUIContext);

  useEffect(() => {
    if (!user && !isAuthenticatorVisible) {
      showAuthenticator();
    }
  }, [user, isAuthenticatorVisible, showAuthenticator]);

  if (!user) {
    // Don't render the protected content until the user is authenticated
    return null;
  }

  return <Outlet />; // Render the protected route content
};

export default ProtectedView;
