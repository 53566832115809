// aws-exports.ts
export const authConfig = {
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
      // identityPoolId: "<your-cognito-identity-pool-id>", //todo
      loginWith: {
        email: true,
      },
      signUpVerificationMethod: "code",
      userAttributes: {
        email: {
          required: true,
        },
      },
      mandatorySignIn: false,
      allowGuestAccess: true,
      passwordFormat: {
        minLength: 8,
        requireLowercase: true,
        requireUppercase: true,
        requireNumbers: true,
        requireSpecialCharacters: true,
      },
    },
  }
};

// const awsConfig = {
//   aws_cognito_region: 'your-cognito-region', // e.g., 'us-east-1'
//   aws_user_pools_id: 'your-user-pool-id',
//   aws_user_pools_web_client_id: 'your-app-client-id',
// };
//
// export default awsConfig;