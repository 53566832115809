import React, {useContext, useEffect, useState} from 'react';
import Logo from './Logo';
import {useTranslation} from 'react-i18next';
import './Header.css';
import {Link} from "react-router-dom";
import {useAuthenticator} from '@aws-amplify/ui-react';
import {fetchUserAttributes} from "@aws-amplify/auth";
import {AuthUIContext} from '../auth/AuthUIContext';

const Header: React.FC = () => {
  const {user, signOut} = useAuthenticator((context) => [context.user]);
  const [username, setUsername] = useState<string | null>();
  const {t} = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const {showAuthenticator} = useContext(AuthUIContext);
  // Toggle main menu open/close
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setActiveDropdown(null); // Close submenus when main menu is toggled
  };

  // Toggle individual submenu
  const toggleSubMenu = (menuName: string) => {
    setActiveDropdown(activeDropdown === menuName ? null : menuName);
  };

  const fetchUserAttri = async () => {
    try {
      if (user) {
        const us = await fetchUserAttributes(); // Use getCurrentUser instead of currentAuthenticatedUser
        console.log("User Attributes:", us);
        return us; // Contains 'name', 'preferred_username', etc.}

      }
    } catch (error) {
      console.error("Error fetching user attributes:", error);
      throw error;
    }
  };

  useEffect(() => {
    if (user && !username) {
      fetchUserAttri().then((us) => {
        setUsername(us?.preferred_username);
      });
    }
  })

  return (
    <header className="header">
      <nav className="header-nav">
        {/* Hamburger icon for mobile screens */}
        <div className="header-hamburger">
          <div className="header-hamburger-menu" onClick={toggleMenu}>
            ☰
          </div>
          <div className="header-hamburger-logo">
            <Link to="/">
              <Logo size="small"/>
            </Link>
          </div>
        </div>


        {/* Menu (links and actions) */}
        <div className={`header-menu ${menuOpen ? 'open' : ''}`}>
          {/* Left section with links */}
          <div className="header-links">
            <div className={`header-link-group ${activeDropdown === 'recipes' ? 'active' : ''}`}>
              <span className="header-link" onClick={() => toggleSubMenu('recipes')}>
                {t('recipes')} <span className="arrow-down">▼</span>
              </span>
              <ul className="dropdown">
                <li><Link to="/recipes?tag=vegetarian">{t('vegetarianRecipes')}</Link></li>
                <li><Link to="/recipes?type=dessert">{t('dessertRecipes')}</Link></li>
                <li><Link to="/recipes?tag=baking">{t('bakingRecipes')}</Link></li>
              </ul>
            </div>
            <div className={`header-link-group ${activeDropdown === 'learn' ? 'active' : ''}`}>
              <span className="header-link" onClick={() => toggleSubMenu('learn')}>
                {t('learn')} <span className="arrow-down">▼</span>
              </span>
              <ul className="dropdown">
                <li><a href="#">{t('howTos')}</a></li>
                <li><a href="#">{t('seasonalCalendar')}</a></li>
                <li><a href="#">{t('avoidFoodWaste')}</a></li>
              </ul>
            </div>
            <div className={`header-link-group ${activeDropdown === 'community' ? 'active' : ''}`}>
              <span className="header-link" onClick={() => toggleSubMenu('community')}>
                {t('community')} <span className="arrow-down">▼</span>
              </span>
              <ul className="dropdown">
                <li><a href="#">{t('stories')}</a></li>
                <li><a href="#">{t('community')}</a></li>
              </ul>
            </div>
          </div>


          {/* Center logo */}
          {!menuOpen && (
            <div className="header-logo">
              <Link to="/">
                <Logo size="large"/>
              </Link>
            </div>
          )
          }

          {/* Right section with "My Space" and search */}
          <div className="header-actions">
            <div className="header-search-icon" style={{cursor: 'pointer'}}>
              <span className="search-icon">&#x1F50D;</span>
            </div>
            <Link to="/my-space" className="header-action">{t('mySpace')}</Link>
            {user ? (
              <button onClick={signOut}>Sign out</button>
            ) : (
              <button onClick={showAuthenticator}>Sign In / Sign Up</button>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
