import React, { useState } from 'react';
import {API_BASE_URL} from "../../apiConfig";

const CreateRecipe: React.FC = () => {
  const [title, setTitle] = useState<string>(''); // State for the recipe title
  const [loading, setLoading] = useState<boolean>(false); // State for button loading
  const [message, setMessage] = useState<string | null>(null); // State for success/error messages

  const handleSubmit = async () => {
    if (!title.trim()) {
      setMessage('Please enter a valid title.');
      return;
    }

    setLoading(true);
    setMessage(null);

    try {
      const response = await fetch(`${API_BASE_URL}/recipes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ title }), // Send the title in the request body
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.error || 'Failed to create recipe');
      }

      const data = await response.json();
      setMessage(`Recipe created successfully! Response: ${JSON.stringify(data)}`);
    } catch (error: any) {
      setMessage(`Error creating recipe: ${error.message || 'Unknown error'}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: '400px', margin: '0 auto', padding: '1rem' }}>
      <h2>Create a Recipe</h2>
      <input
        type="text"
        placeholder="Enter recipe title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        style={{
          width: '100%',
          padding: '0.5rem',
          marginBottom: '1rem',
          border: '1px solid #ccc',
          borderRadius: '4px',
        }}
      />
      <button
        onClick={handleSubmit}
        disabled={loading}
        style={{
          width: '100%',
          padding: '0.5rem',
          backgroundColor: loading ? '#ccc' : '#007BFF',
          color: '#fff',
          border: 'none',
          borderRadius: '4px',
          cursor: loading ? 'not-allowed' : 'pointer',
        }}
      >
        {loading ? 'Creating...' : 'Create Recipe'}
      </button>
      {message && (
        <p
          style={{
            marginTop: '1rem',
            color: message.includes('Error') ? 'red' : 'green',
          }}
        >
          {message}
        </p>
      )}
    </div>
  );
};

export default CreateRecipe;
